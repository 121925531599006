.skills{
    width: 100%;
    padding: 3rem 0;
    background: var(--bg);
    border-top: 1px solid rgb(87, 86, 86);
   
}

.section-heading{
   display: flex;
   justify-content: center;
   width: 100%;

}

.section-heading h2{
    border-bottom: 4px solid var(--underline);
    padding-bottom: 1rem;
}
.skills-container{
    display: grid;
    place-items: center;
    grid-template-columns: repeat(3, 1fr);
    justify-content: space-around;
    width: 100%;
    margin-top: 3rem;

}

@media screen and (max-width: 800px){
    .skills-container{
        grid-template-columns: repeat(1, 1fr);
    }

    .skills-container div{
        margin-top: 2rem;
    }

    progress{
        width: 15rem;
    }
}

.skill-list{
    margin-top: 2rem;
    padding-left: 1rem;
}

progress{
    height: .5rem;
    margin-bottom: .4rem;
}