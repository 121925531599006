.contact-form form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    max-width: 750px;
    min-height: 700px;
    margin: 0 auto;
    padding: 0 20px;
}

.contact-form ul {
    width: 100%;
}

.contact-form input,
textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 1.4rem;
    border: none;
    border-radius: 4px;
    background: rgba(231, 210, 210, 0.5);
}

.contact-form input:focus:not([type="submit"]),
.contact-form input:valid:not([type="submit"]),
textarea:focus {
    outline: none;
    box-shadow: 0px 0px 2px 1px var(--underline);
    background: var(--bg);
}

input {
    height: 3rem;
}

.dark input,
.dark textarea {
    color: #fff;
}

.contact-form textarea {
    height: 10rem;
    resize: none;
}

.half {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    grid-gap: 0.5rem;
}

.half div {
    width: 49%;
}

.heading {
    font-size: 2.5rem;
    font-weight: bold;
    color: var(--underline);
    margin-bottom: 1.5rem;
}

@media screen and (max-width: 459px) {
    .half {
        flex-direction: column;
    }

    .half div {
        width: 100%;
    }

    .heading{
        margin-bottom: .5rem;
    }
}


input.btn {
    background: var(--underline);
}

.half div,
.subject {
    position: relative;
}


.subject label {
    position: absolute;
    top: 18%;
    left: 2.5%;
}

.floating-label {
    position: absolute;
    top: 18%;
    left: 5%;
    pointer-events: none;
    transition: all .3s ease-in-out;
}

input:focus~.floating-label,
input:valid~.floating-label {
    top: -15%;
    left: 2%;
    font-size: .8rem;
    font-weight: bold;
    color: var(--underline);
    padding: 0 1px;
    background: var(--bg);

}

