.navbar{
    display: flex;
    position: absolute;
    top: 5%;
    left: 0;
    z-index: 2;
    justify-content: space-around;
    align-items: center;
    width: 100%;
  }

  nav.dark{
      background-color: black;
  }

  .dark-mode input{
    opacity: 0;
    position: absolute;
  }
  
  .label{
    width: 50px;
    height: 26px;
    background: #111;
    display: flex;
    border-radius: 50px;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    position: relative;
  }
  
  .ball{
    width: 20px;
    height: 20px;
    background: var(--clr-white);
    position: absolute;
    top: 2.5px;
    left: 2px;
    border-radius: 50%;
    transition: transform 0.2s linear;
  }
  
  #dark:checked + .label .ball{
    transform: translateX(24px);
  }
  
  .fa-moon{
    color: pink;
  }
  .fa-sun{
    color: yellow;
  }
  
  .btn {
    text-transform: uppercase;
    padding: 0.375rem 0.75rem;
    display: inline-block;
    background: var(--btn);
    letter-spacing: var(--spacing);
    -webkit-transition: var(--transition);
    transition: var(--transition);
    border-radius: var(--radius);
    color: var(--clr-grey-1);
    font-weight: 700;
    font-size: 0.875rem;
    border: 2px solid transparent;
    cursor: pointer;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  }
  
  .btn:hover{
      color: var(--clr-grey-10);
      background: var(--btn-hover);
  }
  
  header.header{
    position: relative;
    transition: var(--transition);
  }
  
  .section {
    padding: 5rem 0;
  }
  
  .section-center{
      position: relative;
      width: 90vw;
      margin: 0 auto;
      max-width: 1170px;
      display: grid;
      grid-template-columns: repeat(2,1fr);
      place-items: center;
      min-height: 100vh;
  }
  
  .info h1{
    color: var(--font);
  }
  
  @media screen and (min-width:992px){
      .section-center{
          width: 95vw;
      }
  }
  
  .colors{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .colors h4{
    margin-top: 0.7rem;
  }
  .colors ul{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .color{
    position: relative;
    height: 1.2rem;
    width: 1.2rem;
    border-radius: 50%;
    z-index: 999;
    border: none;
    cursor: pointer;
  }
  
  .color:not(:last-child){
    margin-right: 0.25rem;
  }
  
  .theme{
    margin-right: 0.5rem;
    margin-top: 2px;
    letter-spacing: initial;
    color: var(--clr-grey-6);
  }
  
  .active::before{
    content: ' ';
    position: absolute;
    top: -12%;
    left: -12%;
    border: 2px solid var(--clr-grey-6);
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
    display: inline-block;
    vertical-align: middle;
    
  }
  
  .blue-switch{
    background: var(--blue-primary-5);
  }
  
  .yellow-switch{
    background: var(--clr-primary-5);
  }
  
  .pink-switch{
    background: var(--pink-primary-1);
  }
  