.App {
  background: var(--bg);
  height: 100vh;
  transition: all .4s;
}

.dark{
  background-color: var(--bg) !important;
  color: white;
}

::placeholder{
  color: black;
}

/* .blue{
  background-color:  var(--bg);
}

.pink{
  background-color: rgb(241, 213, 217);
}

.yellow{
  background-color: rgb(236, 236, 179);
}
 */
