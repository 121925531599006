.message{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 5%;
    left: 50%;
    transform: translateX(-50%);
    background: #fff;
    width: 25%;
    height: 3rem;
    border-left: #0ac20a 5px solid;
    box-shadow: 4px 10px 20px 0px #b9b9b9
}

.dark .message{
    background: grey;
    box-shadow: 4px 10px 20px 0px #646464;
}

.dark .message p{
    color: #fff;
}

.message p{
    font-size: 1rem;
    margin-bottom: 0;
}

@media screen and (max-width: 850px){
    .message{
        margin-top: 5rem;
        width: 50%;
    }
}

@media screen and (max-width: 460px){
    .message{
        margin-top: 1.8rem;
        width: 80%;
    }
}
    
