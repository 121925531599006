@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");

:root {
    
    /* dark shades of primary color*/
    --clr-primary-1: hsl(43, 86%, 17%);
    --clr-primary-2: hsl(43, 77%, 27%);
    --clr-primary-3: hsl(43, 72%, 37%);
    --clr-primary-4: hsl(42, 63%, 48%);
    /* primary/main color */
    --clr-primary-5: hsl(42, 78%, 60%);
    /* lighter shades of primary color */
    --clr-primary-6: hsl(43, 89%, 70%);
    --clr-primary-7: hsl(43, 90%, 76%);
    --clr-primary-8: hsl(45, 86%, 81%);
    --clr-primary-9: hsl(45, 90%, 88%);
    --clr-primary-10: hsl(45, 100%, 96%);
    /* darkest grey - used for headings */
    --clr-grey-1: hsl(209, 61%, 16%);
    --clr-grey-2: hsl(211, 39%, 23%);
    --clr-grey-3: hsl(209, 34%, 30%);
    --clr-grey-4: hsl(209, 28%, 39%);
    /* grey used for paragraphs */
    --clr-grey-5: hsl(210, 22%, 49%);
    --clr-grey-6: hsl(209, 23%, 60%);
    --clr-grey-7: hsl(211, 27%, 70%);
    --clr-grey-8: hsl(210, 31%, 80%);
    --clr-grey-9: hsl(212, 33%, 89%);
    --clr-grey-10: hsl(210, 36%, 96%);
    --clr-white: #fff;
    --ff-primary: "Roboto", sans-serif;
    --ff-secondary: "Open Sans", sans-serif;
    --transition: all 0.3s linear;
    --spacing: 0.25rem;
    --radius: 0.5rem;
    --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    --max-width: 1170px;
    /* blue primary */
    --blue-primary-1: hsl(184, 91%, 17%);
    --blue-primary-2: hsl(185, 84%, 25%);
    --blue-primary-3: hsl(185, 81%, 29%);
    --blue-primary-4: hsl(184, 77%, 34%);
    --blue-primary-5: #2caeba;
    /* lighter shades of blue color */
    --blue-primary-6: hsl(185, 57%, 50%);
    --blue-primary-7: hsl(184, 65%, 59%);
    --blue-primary-8: hsl(184, 80%, 74%);
    --blue-primary-9: hsl(185, 94%, 87%);
    --blue-primary-10: hsl(186, 100%, 94%);    
     /*pink primary  */
     --pink-primary-1: #F26B4E;
     --pink-primary-7: #e85f75;
     --pink-primary-10: #fcebee;

     /* dark mode */
     --dark-bg: #000000;
     --dark-font: #fff;
     
  }

.blue{
  --bg: var(--blue-primary-10);
  --underline: var(--blue-primary-5);
  --socials: var(--blue-primary-5);
  --btn: var(--blue-primary-5);
  --btn-hover: var(--blue-primary-7);
  --home-img: var(--blue-primary-5);
}

.yellow{
  --bg: var(--clr-primary-10);
  --underline: var(--clr-primary-5);
  --socials: var(--clr-primary-5);
  --btn: var(--clr-primary-5);
  --btn-hover: var(--clr-primary-7);
  --home-img: var(--clr-primary-5);
}

.pink{
  --bg: var(--pink-primary-10);
  --underline: var(--pink-primary-1);
  --socials:var(--pink-primary-1);
  --btn: var(--pink-primary-1);
  --btn-hover:var(--pink-primary-7);
  --home-img: var(--pink-primary-1);

}

.dark{
  --bg: var(--dark-bg) !important;
  --font: var(--dark-font);
  --grey:var(--clr-grey-5)
}


*,
::before,
::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: var(--ff-primary);
  background: var(--clr-white);
  color: var(--clr-grey-1);
  line-height: 1.5;
  font-size: 0.875rem;
}

ul {
  list-style-type: none;
}

a, .contact-btn {
  text-decoration: none;
}

h1,
h2,
h3,
h4 {
  letter-spacing: var(--spacing);
  text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 0.75rem;
  font-family: var(--ff-roboto);
}

h1 {
  font-size: 3rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.25rem;
}
h4 {
  font-size: 0.875rem;
}
p {
  margin-bottom: 1.25rem;
  color: var(--clr-grey-5);
}
@media screen and (min-width: 800px) {
  h1 {
    font-size: 4rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  h4 {
    font-size: 1rem;
  }
  body {
    font-size: 1rem;
  }
  h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  }
}