.projects{
    width: 100%;
    padding: 3rem 0;
    background: var(--bg);
    border-top: 1px solid rgb(87, 86, 86);
}


.project-items{
    padding: 2rem 10%;
    display: flex;
    justify-content: space-around;
  }
  
  .project-item{
    box-shadow: 18px 20px 13px 4px rgb(0 0 0 / 30%);
    background: rgb(201, 217, 223, 30%);
    border-radius: .5rem;
    height: 30rem;
    width: 20rem;
    transition: .3s;
  
  }

  .dark .project-item{
    background: #141414
  }
  
  
  .project-item:hover{
    transform: translateY(-1rem);
  }
  
  .img-container{
    position: relative;
    overflow: hidden;
    border-radius: .5rem;
  }
  
  .img-container::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.3);
  }
  
  .img-container img{
    object-fit: cover;
    width: 20rem;
    height: 15rem;
  }
  
  .project-heading{
    padding: 1rem 1.5rem;
    color: #808080;
    cursor: pointer;
  }
  
  .fa-external-link{
    margin-left: .5rem;
  }
  
  .tech-stack{
    padding: .5rem 1.5rem;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .stack-item{
    padding: .5rem 1rem;
    color: #fff;
    background: #141414;
    border-radius: 1rem;
  }

  .dark .stack-item{
    background: rgb(61, 59, 59);

  }
  
  .button-container{
    display: flex;
    padding: 2.5rem 1rem;
    justify-content: space-around;
  }
  
  .project-btn{
    padding: .5rem 1rem;
    font-weight: bold;
  }
  
  .view-code{
    color: #808080;
    border: 2px solid var(--underline);
  }
  
  .visit-site{
    background: var(--underline);
    color: whitesmoke;
    border-radius: .2rem;
  }

