.hero{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.underline {
    width: 5rem;
    height: 0.25rem;
    margin-bottom: 0.5rem;
    background: var(--underline);
    margin-left: 0;
    margin-right: auto;
  }
  
  .info h4{
    color: var(--clr-grey-5);
  }
  
  .contact-btn{
    margin-top: 1.25rem;
  }

  .resume-btn{
    background: transparent;
    margin-left: .5rem;
    color: var(--underline);
  }

  .fa-arrow-down{
    border: 1px solid var(--underline);
    padding: 5px 4px;
    padding-left: 7.5px;
    border-radius: 50%;
  }
  
  .social-icons{
    display: grid;
    grid-template-columns: repeat(5,1fr);
    justify-items: start;
    width: 20rem;
    margin-top: 3rem;
  }
  
  .social-icon{
    font-size: 1.7rem;
    color: var(--grey);
    transition: var(--transition);
  }
  
  .social-icon:hover{
    color: var(--underline);
  }
  
  .sm-img{
    display: none;
    position: relative;
  }
  
  .sm-img::before{
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    border: 0.25rem solid var(--underline);
    height: 15rem;
    width: 15rem;
    border-radius: 50%;
  }
  
  .sm-img img{
    border-radius: 50%;
    height: 14rem;
    width: 15rem;
  }
  
  .home-img{
    position: relative;
    display: block;
  }
  .home-img img{
    background: #fff;
  }
  
  .home-img::after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transition: .3s;
    background: rgba(242, 107, 78, .1);
    border-radius: var(--radius);
  
  }
  .home-img::before{
    content: '';
    position: absolute;
    top: 2rem;
    right: -2rem;
    width: 100%;
    height: 100%;
    transition: .3s;
    border: 0.15rem solid var(--home-img);
    
  }
  
  .home-img:hover::before{
    top: 1rem;
    right: -1rem;
  }
  .home-img:hover::after{
    background: transparent;
  }
  
  @media screen and (max-width:992px){
    .home-img{
      display: none;
    }
    .section-center{
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
    .sm-img{
      display: initial;
      margin-bottom: 2.5rem;
    }
  }
  
  @media screen and (max-width:460px){
    .sm-img img{
      height: 12rem;
      width: 13rem;
    }
    .sm-img::before{
      height: 13rem;
      width: 13rem;
    }
  
    .navbar{
      top: 1%;
    }
  }
  
  
  .home-img img{
    object-fit: cover;
    position: relative;
    border-radius: var(--radius);
    max-width: 30rem;
    max-height: 30rem;
    background: var(--bg);
  }
  